#chat-container.mobile {
	padding-left: 0;
	padding-right: 0;
}

.chat-wrapper {
	@apply flex relative overflow-x-hidden;
}

.chat-contacts {
	@apply absolute md:static flex flex-col md:mr-3 shrink-0 top-0 bottom-0 z-10;
	@apply w-full md:w-72 lg:w-80 xl:w-[22rem];
}

.girl-messaging-container {
	@apply flex flex-col items-center justify-center h-full;
	animation: late-fade-in 0.3s;
}

.chat-window {
	@apply absolute md:static flex flex-col flex-grow top-0 bottom-0 left-0 right-0;
	transform: translateX(110%);
}

.chat-window.active {
	transform: translateX(0%);
}

.chat-contacts.inactive {
	transform: translateX(-110%);
}

.chat-window.inactive {
	transform: translateX(110%);
}

.chat-message {
	@apply px-4 py-2 mb-1 rounded-2xl w-fit max-w-3/4 relative;
	white-space: pre-line;
}

.chat-message.image {
	@apply px-1 py-1;
}

.chat-message.left {
	@apply rounded-tl-xs;
	background-color: #f1f1f1;
}

.chat-message.right {
	@apply bg-blue text-white rounded-tr-xs ml-auto;
}

.chat-message.left + .chat-message.right {
	@apply mt-4;
}
.chat-message.right + .chat-message.left {
	@apply mt-4;
}

.chat-message .link {
	text-decoration: underline;
}

.chat-message.right .link {
	color: white;
}

.chat-message.error {
	@apply bg-dark-red;
}

.chat-message .chat-tooltip {
	@apply absolute text-secondary;
	transition: opacity 0.15s;
	visibility: hidden;
	opacity: 0;
	z-index: -1;
	top: 50%;
	transform: translateY(-50%);
}

.chat-message.right .chat-tooltip {
	right: calc(100% + 10px);
}

.chat-message.left .chat-tooltip {
	left: calc(100% + 10px);
}

.chat-message:hover > .chat-tooltip {
	visibility: visible;
	opacity: 1;
	z-index: 1;
}

.chat__message-content {
	word-break: break-word;
	transition: margin-right 0.3s;
}

.chat-message-img {
	@apply rounded-xl cursor-pointer w-52 h-52 bg-white object-cover;
}

.chat__seen-mark {
	@apply absolute right-2.5 bottom-1 text-white hidden;
	animation: fade-in 0.3s;
}

.chat-message.left .chat__seen-mark {
	@apply text-primary;
}

.chat-message__spinner {
	@apply absolute right-2.5 bottom-1 text-white hidden;
}

.chat-message .chat__message-content.seen,
.chat-message.loading .chat__message-content {
	@apply mr-3.5;
}

.chat-message.image .chat__message-content.seen,
.chat-message.image.loading .chat__message-content {
	@apply mr-6;
}

.chat__message-content.seen ~ .chat__seen-mark {
	@apply block;
}

.chat-message.loading .chat-message__spinner {
	display: block;
}

.chat__send-btn {
	@apply primary rounded-full p-3.5 ml-3 cursor-pointer;
	transition: all 0.2s !important;
}

.chat__send-btn:hover {
	transform: scale(1.05);
}

.chat-contacts__unseen-bubble {
	@apply rounded-full bg-blue text-white w-5 h-5 text-xs flex justify-center items-center;
	animation: scale-in 0.2s;
}
