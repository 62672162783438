/* Edit profile */
.edit-avatar-label {
	@apply absolute top-0 left-0 w-full h-full rounded-full cursor-pointer bg-transparent;
}

.edit-avatar-bubble {
	@apply absolute top-3/4 right-0 cursor-pointer rounded-full transition-colors bg-white shadow-md p-3;
}

.edit-avatar-icon {
	@apply text-darkest-gray;
	font-size: 18px;
}

/* Products */
.add-product-card {
	@apply bg-light-blue flex
			items-center justify-center text-blue rounded-lg md:rounded-xl cursor-pointer w-full md:max-w-md;
	@apply hover:bg-light-blue-intense;
	transition: background-color 0.2s;
}

.add-product-image-btn,
.product-image-preview {
	@apply rounded-md w-[96px] h-[96px] sm:w-[120px] sm:h-[120px];
}

.product-image-preview,
.product-personalization-image-preview {
	@apply object-cover;
	transition: opacity 0.15s;
}

.product-personalization-image-preview {
	@apply rounded-md w-28 h-28;
}

.product-image-preview.contain {
	@apply !object-contain;
}

.product-image-preview:hover,
.product-personalization-image-preview:hover {
	opacity: 0.8;
}

.add-product-image-btn {
	@apply flex flex-col items-center justify-center cursor-pointer primary-light;
	@apply mr-1.5 mb-1.5 hover:bg-light-blue-intense;
	transition: background-color 0.2s;
}

.delete-image-container {
	@apply absolute cursor-pointer rounded-md z-10 w-full h-full top-0 opacity-0 hover:opacity-100 flex items-center justify-center;
	transition: opacity 0.15s;
}

@media (hover: hover) {
	.edit-avatar-bubble:hover {
		@apply bg-gray;
	}
}
