.artists-container,
.products-container {
	@apply flex flex-wrap justify-around sm:justify-start;
}

.products-container {
	@apply -mr-1 sm:-mr-3;
}

.artists-container {
	@apply -mr-2;
}

.page-button {
	@apply w-10 h-10 flex items-center justify-center bg-white rounded-md text-secondary mr-2 cursor-pointer font-semibold;
}

.page-button.current-page {
	@apply bg-blue text-white;
}

.paginator-more {
	@apply mr-2 text-secondary;
}

.paginator-btn {
	@apply p-1 bg-white text-secondary cursor-pointer rounded-md;
}

.paginator-btn.prev {
	@apply mr-2;
}

.paginator-btn.disabled {
	@apply bg-dark-gray cursor-default text-light-secondary;
}

.paginator-btn,
.page-button {
	transition: transform 0.2s;
}

.paginator-btn:hover,
.page-button:hover {
	transform: scale(1.1);
}

.subcategory-btn {
	@apply border-1 border-[#ccc] rounded-md text-secondary py-2 px-3 cursor-pointer;
}

.subcategory-btn.active {
	@apply bg-blue text-white border-blue;
}
