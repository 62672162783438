.elevated-sm {
	box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.02);
}

/* Product cards */
.product-card {
	@apply relative block bg-white rounded-md sm:rounded-xl elevated-sm shrink-0 select-none overflow-hidden;
	@apply p-0 sm:p-2 w-[168px] sm:w-[198px] md:w-[230px];
	animation: fade-in 0.2s;
	max-width: 280px;
}

.product-card .product-title {
	@apply text-md mt-2.5 mb-1;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* Artist cards */
.artist-card {
	@apply flex flex-1 flex-col items-center bg-white rounded-xl px-1 py-5 mr-2 mb-2 xl:mr-3 xl:mb-3 overflow-hidden;
	@apply min-w-[180px] xl:min-w-[240px] max-w-[240px];
	align-self: stretch;
	transition: box-shadow 0.1s;
	animation: fade-in 0.2s;
}

#home-artists-swiper .artist-card {
	@apply mr-0 mb-0 mx-auto w-[200px] md:w-[240px] text-md md:text-base hover:shadow-none;
}

.artist-card-name {
	@apply font-semibold text-center;
}

.artist-card-category {
	@apply bg-gray py-1 px-3 rounded-sm text-secondary m-1 text-sm font-semibold;
}

.artist-card:hover {
	box-shadow: 0 0 0 3px rgba(27, 105, 213, 0.3);
}
