@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	.responsive-square {
		padding-top: 50%;
		padding-bottom: 50%;
		height: 0;
		display: -webkit-box;
		-webkit-box-pack: center;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	/* For FireFox */
	.custom-scroll {
		scrollbar-color: #ddd #fff;
	}

	/* Works on Chrome, Edge, and Safari */
	.custom-scroll::-webkit-scrollbar {
		width: 13px;
	}

	.custom-scroll::-webkit-scrollbar-track {
		@apply rounded-xl;
		background: #fff;
	}

	.custom-scroll::-webkit-scrollbar-thumb {
		background-color: #e0e0e0;
		border-radius: 20px;
		border: 3px solid #fff;
	}

	.container {
		@apply px-3;
	}

	.separator {
		@apply mx-3 h-full;
		width: 1px;
	}

	.link {
		@apply text-blue cursor-pointer;
	}
	.link:hover {
		text-decoration: underline;
	}

	.primary {
		@apply bg-blue text-white;
		transition: background-color 0.2s, color 0.2s;
	}
	.primary:hover {
		@apply bg-dark-blue;
	}
	.primary:active {
		@apply bg-darkest-blue;
	}

	.success {
		@apply bg-green text-white;
		transition: background-color 0.2s, color 0.2s;
	}
	.success:hover {
		@apply bg-dark-green;
	}
	.success:active {
		@apply bg-darkest-green;
	}

	.danger {
		@apply bg-red text-white;
		transition: background-color 0.2s, color 0.2s;
	}
	.danger:hover {
		@apply bg-dark-red;
	}
	.danger:active {
		@apply bg-darkest-red;
	}

	.warning {
		@apply bg-orange text-white;
		transition: background-color 0.2s, color 0.2s;
	}
	.warning:hover {
		@apply bg-dark-orange;
	}
	.warning:active {
		@apply bg-darkest-orange;
	}

	.info {
		@apply bg-purple text-white;
		transition: background-color 0.2s, color 0.2s;
	}

	.primary-light {
		@apply bg-light-blue text-blue;
		transition: background-color 0.2s, color 0.2s;
	}
	.primary-light:hover {
		@apply bg-light-blue-intense;
	}

	.success-light {
		@apply bg-light-green text-green;
		transition: background-color 0.2s, color 0.2s;
	}
	.success-light:hover {
		@apply bg-light-green-intense;
	}

	.danger-light {
		@apply bg-light-red text-red;
		transition: background-color 0.2s, color 0.2s;
	}
	.danger-light:hover {
		@apply bg-light-red-intense;
	}

	.warning-light {
		@apply bg-light-orange text-orange;
		transition: background-color 0.2s, color 0.2s;
	}
	.warning-light:hover {
		@apply !bg-light-orange-intense;
	}

	.info-light {
		@apply bg-light-purple text-purple;
		transition: background-color 0.2s, color 0.2s;
	}
	.info-light:hover {
		@apply bg-light-purple-intense;
	}

	.dark {
		@apply bg-primary text-white;
	}
	.dark:hover {
		@apply bg-black;
		transition: background-color 0.2s, color 0.2s;
	}
	.dark:active {
		@apply bg-primary;
	}

	.light {
		@apply bg-gray text-secondary;
		transition: background-color 0.2s, color 0.2s;
	}
	.light:hover {
		@apply bg-dark-gray;
	}
	.light:active {
		@apply bg-gray;
	}
}

:root {
	color-scheme: only light;
	--toastify-toast-width: fit-content;
	--toastify-color-success: #42c66d;
	--toastify-color-error: #ff4646;
	--toastify-color-warning: #ed8346;
}

.Toastify__toast-container {
	min-width: 300px;
	max-width: 500px;
}

html {
	font-family: 'Lato', sans-serif;
	-webkit-tap-highlight-color: transparent;
	height: 100%;
}

body {
	@apply text-primary;
	height: 100%;
}

.font-brush {
	font-family: 'Good Brush', sans-serif;
}

.system-font {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell,
		'Helvetica Neue', sans-serif;
}

#root {
	height: 100%;
}

#modal-root {
	position: relative;
	min-width: 0;
	min-height: 0;
}

#view {
	min-height: 100%;
}

h1 {
	@apply text-3xl lg:text-4xl xl:text-5xl;
}

h2 {
	@apply text-2xl lg:text-4xl;
}

h3 {
	@apply text-xl lg:text-2xl xl:text-3xl;
}

h4 {
	@apply text-base sm:text-xl;
}

input[type='checkbox'] {
	cursor: pointer;
}

input[type='checkbox'],
input[type='checkbox'] + label {
	cursor: pointer;
	vertical-align: middle;
}

input[type='checkbox'] {
	width: 14px;
	height: 14px;
}

::placeholder {
	/* @apply text-secondary !important; */
	/* Chrome, Firefox, Opera, Safari 10.1+ */
	@apply !text-tertiary;
	opacity: 1; /* Firefox */
}

.collapsible {
	@apply w-full border-2 border-gray rounded-lg my-3 overflow-hidden;
}

.collapsible__header {
	@apply flex bg-gray px-4 py-3 justify-between items-center cursor-pointer select-none;
}

.collapsible__body {
	max-height: 0px;
	transition: max-height 0.2s;
	position: relative;
}

.profile-avatar {
	@apply w-[100px] h-[100px] md:w-[150px] md:h-[150px];
}

.loading-spinner-container {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;
	transform: translate(-50%, -50%);
}

.loading-spinner {
	animation: rotate 1.5s linear;
	animation-iteration-count: infinite;
	will-change: transform;
}

/* Input fields */
.input-field {
	@apply w-full bg-gray rounded-sm px-3 py-2 outline-none;
	transition: background-color 0.25s, border-color 0.25s;
	border: 2px solid #f4f4f4;
}

.input-field:disabled {
	cursor: not-allowed;
}

.input-field:not(:disabled):hover {
	@apply bg-white;
}

.input-field:focus,
.input-field.text-area:focus {
	@apply bg-white;
	border-color: rgba(59, 168, 240, 0.4);
}

.input-field.text-area {
	@apply bg-white;
	border: 1px solid #ddd;
	box-shadow: 0 1px 4px 0 rgba(34, 34, 34, 0.1) inset;
}

.search-field {
	@apply flex items-center mb-4 relative;
}

.search-field-icon {
	@apply absolute;
	right: 0.75rem;
}

.search-field > .input-field {
	padding-right: calc(0.75rem + 18px + 5px);
}

/* .input-field:focus {
	background-color: #fff;
	border-color: #eee;
} */

.input-select {
	@apply flex items-center bg-gray border-2 border-gray rounded-md popover-parent justify-between hover:bg-dark-gray hover:border-dark-gray;
	transition: background-color 0.2s, border-color 0.2s;
}

.input-select.white {
	background: white;
	border-color: white;
}

.input-select.error {
	@apply danger-light border-light-red;
}

.input-select.error:hover {
	@apply bg-white;
}

input[type='radio'] {
	cursor: pointer;
}

.line-input {
	@apply text-center mx-1 p-1 bg-transparent border-b-1 border-primary;
}

/* Removes the arrows in number inputs: Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.category-button {
	@apply flex items-center justify-between py-2 px-3 rounded-md;
	transition: 0.2s background-color, 0.2s color;
}

.category-button:hover {
	@apply bg-light-gray;
}

/* Tabs */
.tab {
	@apply w-1/2 text-center py-3 cursor-pointer;
}

.tab.active {
	@apply primary-light;
}

.tab.inactive {
	@apply text-secondary;
}

/* Animated Fill Effect */
.animated-fill {
	position: relative;
}

.animated-fill::before {
	@apply block rounded-full absolute top-0 left-0 h-full w-full;
	content: '';
	background-color: #f4f4f4;
	z-index: -1;
	opacity: 0;
	transition: transform 0.17s, opacity 0.17s;
	transform: scale(0.8);
}

.animated-fill:hover::before {
	transform: scale(1.05);
	opacity: 1;
}

/* Popover */
.popover-parent {
	@apply relative cursor-pointer select-none;
}

.popover {
	@apply absolute bg-white rounded-lg z-40 p-2 opacity-0 cursor-default;
	visibility: hidden;
	transform: translateY(20px);
	transition: opacity 0.2s, transform 0.2s;
	top: calc(100% + 8px);
	box-shadow: 0 4px 15px rgb(0 0 0 / 8%);
	will-change: transform, opacity;
}

.select-popover {
	@apply right-0 custom-scroll min-w-full overflow-x-hidden text-primary;
	max-height: 300px;
	overflow-y: auto;
}

.popover.open {
	visibility: visible;
	opacity: 1;
	transform: translateY(0);
}

.popover.hiding {
	opacity: 0;
	transform: translateY(20px);
}

.popover .select-option {
	@apply px-4 py-2 cursor-pointer hover:bg-light-gray rounded-md overflow-hidden overflow-ellipsis whitespace-nowrap text-center;
}

.popover .select-option.selected {
	@apply primary-light;
}

/* Swiper */
.swiper {
	@apply rounded-xs;
}

.swiper-slide {
	@apply w-fit;
}

.swiper-button-next::after,
.swiper-button-prev::after {
	text-shadow: black 0px 0px 2px;
}

.swiper-button-prev {
	left: 12px;
}

.swiper-pagination-bullet-active {
	background: white;
}

.product-cards-swiper {
	@apply bg-gray rounded-lg sm:rounded-lg relative p-2 sm:p-5 sm:pr-3;
}

.product-heart {
	@apply absolute top-0.5 right-0.5 rounded-full bg-white p-2 z-10 cursor-pointer;
	transition: transform 0.15s;
}

.product-heart:hover {
	transform: scale(1.1);
}

.product-heart:active {
	cursor: pointer;
}

.swiper-slide .product-card:active {
	@apply cursor-grabbing;
}

.swiper-slide .artist-card:active {
	@apply cursor-grabbing;
}

.swiper-btn {
	@apply bg-gray p-0.5 sm:p-1.5 rounded-md cursor-pointer select-none border-2 border-gray hover:bg-transparent;
	transition: background-color 0.2s;
}

.product-swiper {
	@apply mb-2 rounded-md;
}

.product-swiper .swiper-button-prev,
.product-swiper .swiper-button-next {
	@apply hidden sm:block;
}

.product-thumb-swiper {
	@apply py-1 rounded-md;
}
.product-thumb-swiper .swiper-slide {
	@apply mr-1.5 md:mr-2 rounded-md overflow-hidden;
}
.product-thumb-swiper img {
	@apply w-16 h-16 md:w-20 md:h-20 opacity-70 cursor-pointer;
	transition: opacity 0.2s;
}
.product-thumb-swiper .swiper-slide-thumb-active img {
	@apply opacity-100;
}
.product-thumb-swiper img:hover {
	@apply opacity-100;
}

.see-more-cover {
	@apply absolute bottom-0 left-0 w-full h-1/2;
	background: linear-gradient(0deg, #fff 0%, #fff 25%, transparent 75%);
}

/* Footer */
.footer-link {
	@apply relative block w-fit mb-1 cursor-pointer;
}
.footer-link::after {
	content: '➜';
	opacity: 0;
	position: absolute;
	left: calc(100%);
	top: 1px;
	transition: transform 0.2s, opacity 0.2s;
}
.footer-link:hover::after {
	opacity: 1;
	transform: translateX(5px);
}

.footer-hr {
	@apply bg-white mb-4;
	width: 100px;
	height: 2px;
}

/** Reviews **/
.reviews-button {
	@apply flex items-center text-secondary select-none text-md sm:text-base md:text-lg;
	transition: color 0.2s;
}

.reviews-button.active {
	@apply text-primary font-semibold;
}

.reviews-tab {
	@apply w-1/2 flex items-center justify-center py-3;
}

.reviews-tab.active {
	border-bottom: 2px solid black;
}

.reviews-count {
	@apply text-xs md:text-sm light px-1.5 sm:px-2 md:px-3 py-0.5 rounded-md ml-2;
	transition: color 0.2s, background-color 0.2s;
}

.reviews-indicator {
	@apply w-1/2 bg-primary h-full rounded-xs;
	transition: transform 0.2s;
}

.verified-review-tooltip {
	@apply absolute top-full mt-2 px-3 py-2 bg-green text-white whitespace-nowrap rounded-sm;
	left: 50%;
	transform: translateX(-50%);
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.2s;
}

@media screen and (min-width: 500px) {
	.verified-review-tooltip {
		@apply left-full mt-0 ml-2;
		top: 50%;
		transform: translateY(-50%);
	}
}
.verified-review-mark:hover + .verified-review-tooltip {
	visibility: visible;
	opacity: 1;
}

.public-profile-container {
	animation: fade-in 0.15s;
}

.image-visible {
	display: block;
	animation: fade-in 0.15s;
}

.image-hidden {
	display: none;
	opacity: 0;
}

.tooltip-text {
	@apply absolute px-3 py-2 text-sm bg-primary text-white w-[200px] rounded-md;
	bottom: calc(100% + 5px);
	left: 50%;
	transform: translateX(-50%);
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.2s;
}

.tooltip-trigger {
	@apply relative cursor-pointer align-middle;
}

.tooltip-trigger:hover .tooltip-text {
	visibility: visible;
	opacity: 1;
}

.fade-in {
	animation: fade-in 0.2s;
}

.late-fade-in {
	animation: late-fade-in 1s !important;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes late-fade-in {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes scale-in {
	0% {
		opacity: 0;
		transform: scale(0);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

/* clears the 'X' from Chrome search input */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	display: none;
}

/* Removes the arrows in number inputs: Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Status icon */
.status-icon.approved {
	@apply text-green;
}

.status-icon.rejected {
	@apply text-red;
}

.status-icon.pending {
	@apply text-orange;
}

.status-icon.disabled {
	color: #ddd;
}
