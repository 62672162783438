header {
	@apply bg-white sticky top-0 z-50 py-2 md:py-3;
	border-bottom: 1px solid #eee;
}

.logo-half {
	@apply py-[2px] px-[5px] text-base;
	@apply sm:py-[3px] sm:tracking-[2px] sm:px-[8px] sm:text-lg;
	border-radius: 4px;
}

.logo-black {
	@apply sm:pr-[5px];
	background-color: black;
	color: white;
	font-family: 'Logo';
	font-weight: 700;
}

.logo-transparent {
	@apply sm:pl-[5px];
	font-family: 'Logo';
	font-weight: 400;
}

.user-photo {
	@apply rounded-full object-cover;
	max-width: none;
	animation: fade-in 0.3s;
}

#user-dropdown,
#nav-mobile-menu {
	@apply w-56;
}

#user-dropdown .user-item {
	@apply flex p-3 rounded-md hover:bg-light-gray cursor-pointer;
}

#nav-mobile-menu {
	@apply left-0;
}

.navbar__badge {
	@apply absolute rounded-full flex justify-center items-center bg-blue text-white text-xs;
	top: 5px;
	right: 4px;
	padding: 0px 5.5px;
	animation: scale-in 0.3s;
}

#side-menu {
	@apply absolute top-0 left-0 bottom-0 p-4 rounded-none overflow-hidden overflow-y-auto;
	width: 100%;
	max-width: 300px;
	transition: transform 0.3s;
	will-change: transform;
	animation: slide-in-right 0.2s !important;
}

@keyframes slide-in-right {
	0% {
		opacity: 0;
		transform: translateX(-50%);
	}

	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
