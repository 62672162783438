.login-container {
	@apply w-full bg-white rounded-xl max-w-[370px];
	max-height: 100vh;
	overflow-y: auto;
}

.login-banner,
.register-banner {
	@apply rounded-t-xl;
	overflow: hidden;
	margin-top: -30px;
}

@media screen and (max-width: 390px) {
	.login-container {
		max-width: 340px;
	}
}

@media screen and (max-height: 745px) {
	#login-panel.register-page .login-container {
		margin: 0 auto;
		max-width: 370px;
		border-radius: 0;
		min-height: 100%;
	}

	.register-banner {
		border-radius: 0 !important;
	}
}

@media screen and (max-height: 620px) {
	#login-panel.login-page .login-container {
		margin: 0 auto;
		max-width: 370px;
		border-radius: 0;
		min-height: 100%;
	}

	.login-banner {
		border-radius: 0 !important;
	}
}
