/* Buttons */
.btn {
	@apply relative px-5 py-2.5 rounded-md;
	transition: background-color 0.2s, color 0.2s, transform 0.2s;
}

.btn-outline {
	@apply bg-transparent text-blue;
	box-shadow: inset 0px 0px 0px 2px #378efb;
}
.btn-outline:hover {
	@apply !bg-light-blue;
}
.btn-outline:active {
	@apply !bg-light-blue-intense;
}

.provider-login-button {
	@apply flex text-white rounded-sm w-full px-5 py-2.5 relative items-center text-md;
	transition: all 0.25s;
}

.provider-login-button .icon {
	@apply flex items-center absolute left-3;
}

.provider-login-button .separator {
	@apply mx-0 ml-2;
	color: rgba(255, 255, 255, 0.8);
}

.btn-google:hover {
	@apply bg-dark-google;
}

.btn-google:active {
	@apply bg-darkest-google;
}

.btn-facebook:hover {
	@apply bg-dark-facebook;
}

.btn-facebook:active {
	@apply bg-darkest-facebook;
}

.modal-close-btn {
	@apply absolute top-2 right-2 sm:top-5 sm:right-4 w-9 h-9;
	@apply bg-[#e7e7e7] text-white flex items-center justify-center rounded-full cursor-pointer hover:scale-110;
	transition: transform 0.2s;
}

.join-discord-button {
	@apply bg-white text-[#3440bd] cursor-pointer px-10 py-2 rounded-full;
	transition: transform 0.2s;
}

.join-discord-button:hover {
	@apply scale-105;
}

.image-preview-delete-button {
	@apply block mx-auto mt-10 bg-white text-red font-semibold;
}

.change-cart-item-quantity {
	@apply light p-1 rounded-full cursor-pointer;
}

@keyframes rotate {
	0% {
		transform: rotate(0) scaleX(-1);
	}
	100% {
		transform: rotate(360deg) scaleX(-1);
	}
}
