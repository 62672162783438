#creative-girl-container {
	@apply md:w-[330px] lg:w-[400px] xl:w-[430px];
	max-width: none;
	margin-bottom: -30px;
	margin-top: -30px;
	margin-right: -10px;
	animation: fade-in 0.2s;
}

#creative-girl-placeholder {
	@apply md:w-[310px] md:h-[274px] lg:w-[410px] lg:h-[338.58px];
}

.home-title {
	@apply text-[36px] md:text-[56px] xl:text-[72px] pl-2 md:pl-0 text-center md:text-left;
	font-family: 'Good Brush', sans-serif;
}

.home-subtitle {
	@apply mt-5 text-md md:text-base md:mt-0 md:mb-0 text-center md:text-left;
}

.roadmap-stage-name {
	@apply ml-1 md:ml-2;
}

.roadmap-floating-pointing-arrow {
	@apply absolute;
	bottom: calc(100%);
	left: 50%;
	transform: translateX(-50%);
	animation: up-and-down 2s infinite;
}

@keyframes up-and-down {
	0% {
		transform: translateX(-50%) translateY(0);
	}
	50% {
		transform: translateX(-50%) translateY(-30%);
	}
	100% {
		transform: translateX(-50%) translateY(0);
	}
}

.join-our-discord-title {
	@apply text-white text-[24px] mb-6 md:text-[28px] text-center md:text-left;
	font-weight: 700;
}

.category-card {
	transition: background-color 0.2s, color 0.2s, transform 0.2s;
	backface-visibility: hidden;
}

.wave {
	transform: scaleY(0.5);
}

#home-artists-swiper .swiper-slide {
	@apply mr-0 md:mr-4;
}

/* How It Works */
.how-it-works-tab {
	@apply font-semibold relative text-dark-secondary;
	cursor: pointer;
}

.how-it-works-tab.active {
	@apply text-primary;
}

.active-tab-indicator {
	@apply bg-primary;
	position: absolute;
	top: calc(100% + 4px);
	width: 100%;
	height: 3px;
	border-radius: 3px;
}

.hiw-step {
	@apply mb-6;
}

.hiw-step-bubble {
	@apply w-10 h-10;
	@apply rounded-full bg-light-blue flex justify-center items-center text-blue mr-4 shrink-0 font-semibold;
	font-size: 17px;
}

.hiw-step-title {
	@apply text-lg font-bold;
}

.hiw-step-subtitle {
	@apply -mt-1 ml-14 text-secondary;
}

.hiw-benefits-container {
	@apply bg-gray rounded-lg px-5 pt-4 pb-6 w-[320px];
}

.hiw-benefit {
	@apply bg-white py-2 px-4 mt-3 rounded-lg w-fit hover:bg-light-blue cursor-default;
	transition: background-color 0.2s;
}

.products-grid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
}

.category-grid {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
}

.home-category-image {
	@apply rounded-full object-cover w-32 h-32 md:w-36 md:h-36 hover:scale-95;
	transition: all 0.2s;
}

.home-category-image:hover {
	box-shadow: 0px 0px 0px 10px #f0f0f0;
}

.home-subcategory-card {
	@apply w-max place-self-center border-1 rounded-xl border-[#e0e0e0] hover:shadow-lg mb-6 xl:mb-6;
	transition: all 0.2s;
}

.home-subcategory-card:hover {
	transform: translateY(-5px);
}

.home-subcategory-image {
	@apply object-cover w-36 h-32 md:w-56 md:h-48 rounded-xl rounded-b-none;
}

@media screen and (max-width: 1300px) {
	.products-grid {
		grid-template-columns: repeat(3, 1fr);
	}
	.category-grid {
		grid-template-columns: repeat(3, 1fr);
	}
}

@media screen and (max-width: 1000px) {
	.products-grid {
		grid-template-columns: repeat(2, 1fr);
	}
	.category-grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

.products-grid .product-card {
	@apply w-[95%] mx-auto;
}
