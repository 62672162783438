/* New modal */
.modal-container {
	@apply fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center overflow-auto;
	background-color: rgba(0, 0, 0, 0.4);
	backdrop-filter: blur(5px);
	animation: fade-in 0.2s;
	transition: opacity 0.2s;
	z-index: 99;
	will-change: transform;
}

.modal-container.exiting {
	opacity: 0;
}

.modal-container.exiting .modal-body {
	transform: scale(0.8);
	opacity: 0;
}

.modal-body {
	@apply shrink-0;
	box-shadow: 0 4px 10px rgb(0 0 0 / 5%);
	transition: transform 0.2s, opacity 0.2s;
	animation: modal-in 0.2s;
	will-change: transform;
	max-height: 100vh;
	overflow-y: auto;
}

.modal-close-button {
	@apply absolute top-2 right-2 p-2.5 rounded-full cursor-pointer text-white;
	transition: transform 0.2s;
}

.modal-close-button.small {
	@apply p-2;
}

.modal-close-button:hover {
	transform: scale(1.1);
}

.modal-close-button.dark {
	background-color: rgba(0, 0, 0, 0.25);
}

.modal-close-button.light {
	background-color: rgba(255, 255, 255, 0.12);
}

.modal-close-button.gray {
	@apply !text-[#888] bg-gray;
}

@keyframes modal-in {
	0% {
		opacity: 0;
		transform: scale(0.8);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.modal-image-preview {
	@apply rounded-lg;
	min-width: 150px;
	max-width: calc(100vw - 12px);
	max-height: calc(100vh - 12px);
}

.image-picker-preview-modal {
	@apply bg-transparent;
	width: 85vmin;
	max-width: 600px;
}

.image-picker-preview-modal img {
	@apply rounded-md object-cover w-full h-full;
	max-height: calc(100vh - 100px);
}

.image-picker-preview-modal img.contain {
	@apply !object-contain;
}
